<template>
	<layout-default-new>
		<div class="about">
			Our Company: &nbsp;{{
				$store.getters.getOrderReportData.company.company_name
			}}<br />
			Customer: &nbsp;{{
				$store.getters.getOrderReportData.customer.customer_title
			}}<br />
			Order Number: &nbsp;{{ $store.getters.getOrderReportData.order_number
			}}<br />
			Date: &nbsp;{{ $store.getters.getOrderReportData.order_date }}<br />
			Exchange Unit: &nbsp;{{
				$store.getters.getOrderReportData.order_exchange_unit
			}}<br />
			Exchange Rate: &nbsp;{{
				$store.getters.getOrderReportData.order_exchange_rate
			}}<br />
			OrderLanguage: &nbsp;{{ $store.getters.getOrderReportData.order_language
			}}<br />
			<b-button
				class="is-info margin-top-15"
				@click="openPDF"
				style="width: 250px"
				><i class="fas fa-search"></i>&nbsp; Preview</b-button
			>
			<div>
				<b-button
					class="is-success margin-top-15"
					@click="downloadPDF"
					style="width: 250px"
					><i class="fas fa-download"></i>&nbsp; Download</b-button
				>
			</div>
			<div>
				<b-button
					class="is-danger margin-top-15"
					@click="printPDF"
					style="width: 250px"
					><i class="fas fa-print"></i>&nbsp; Print</b-button
				>
			</div>
			<div>
				<b-button
					v-if="false"
					class="is-primary margin-top-15"
					@click="emailPDF"
					style="width: 250px"
					><i class="fas fa-envelope-square"></i>&nbsp; Email</b-button
				>
			</div>
			<div>
				<b-button
					class="is-success margin-top-15"
					@click="createAnotherOrder"
					style="width: 250px; margin-top: 50px"
				>
					<i class="far fa-plus-square"></i>&nbsp; Create Another Order
				</b-button>
			</div>
			<div>
				<b-button
					class="is-info margin-top-15"
					@click="viewAnotherOrder"
					style="width: 250px"
				>
					<i class="fas fa-search"></i>&nbsp; View Another Order
				</b-button>
			</div>
		</div>
	</layout-default-new>
</template>
<script>
import useOrderReportData from '@/_srcv2/pages/order/_shared/get-order-report/script/OrderReport.js'
import Store from '@/store'
import Router from '@/router'
import { onMounted } from '@vue/composition-api'

export default {
	key: Store.getters.getKeyOrderReport,
	name: 'OrderReportPdf',
	setup() {
		console.log('OrderReportPdf is created')
		onMounted(() => console.log('OrderReportPdf onMounted is fired'))
		// ----------------------------------------------------------------------------
		const { getOrderReportData } = useOrderReportData()
		console.log('getOrderReportData', getOrderReportData)
		const getData = () => {
			return new Promise((resolve, reject) => {
				const dd = getOrderReportData()
				if (dd !== undefined || null) {
					resolve(dd)
				} else {
					const errorObject = {
						msg: "Data couldn't be fetched from store",
					}
					reject(errorObject)
				}
			})
		}
		// ----------------------------------------------------------------------------------------
		const createReport = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					console.log('******//// dd ****', dd)
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					const pdf = pdfMake.createPdf(dd)
					resolve(pdf)
				} else {
					const errorObject = {
						msg: "The report couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		const openPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.open())
		}
		const fileName = Store.getters.getOrderReportData.order_number
			.trim()
			.replace(' ', '')
			.toLowerCase()
		const downloadPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.download(`${fileName}.pdf`))
		}
		const printPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.print())
		}
		const createAnotherOrder = () => Router.push({ name: 'AddOrder' })
		const viewAnotherOrder = () => Router.push({ name: 'ViewOrder' })
		const emailPDF = () =>
			alert(
				'This function is not supported on the current version of application!!!',
			)
		return {
			openPDF,
			downloadPDF,
			printPDF,
			emailPDF,
			createAnotherOrder,
			viewAnotherOrder,
		}
	},
}
</script>
<style scoped>
.margin-top-15 {
	margin-top: 15px;
}
</style>
